
import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { graphql } from "gatsby"

import PageMetaData from "components/pageMetaData"
import { postHeader } from "graphQLFragments"
import PostFeed from "components/postFeed"
import ResponsiveImage from "components/responsiveImage"
import parseHtml from "helper/parseHtml"
import RichText from "components/richText"

import styles from "./styles.module.scss"

import { add as addPosts } from "state/post"
import { update as updateApp } from "state/app"

export const query = graphql`

	query ContributorBySlug($uid: String! $first: Int = 5 $after: String) {
	    
	    prismic {

		    contributor(uid: $uid lang: "en-us") {
		      
		  		_meta { uid }
		      
		  		name

				profile_images { profile_image }

				profile_links {

					profile_link_title

					profile_link {

						... on PRISMIC__ExternalLink {
                    
		                    url
		                    
	                  	}

					}

				}

				description

		    }

			posts: allPost_defaults(first: $first, after: $after sortBy: meta_firstPublicationDate_DESC where: { primary_credit_fulltext: $uid visibility: "visible" }) {

				pageInfo {

			      hasNextPage

			      endCursor

			    }

				edges {

					node {

						...PostHeader

					}

				}

			}

		}

	}

`;

function ContributorTemplate ({

	pageContext: {

		uid

	},

	prismic,

	location,

	contributor,
	posts,
	pageInfo,
	loadingPosts,

	updateApp,
	addPosts,
	ref

}) {

	if(!contributor)

		return null;

	const { 
		name,
		profile_images,
		profile_links,
		description

	} = contributor;

	return (

		<div ref={ref} className={styles.root}>

			<PageMetaData 

				title={(name || [{}])[0].text}
				description={(description || [{}])[0].text}
				imageUrl={(((profile_images || [])[0] || { profile_image: {} }).profile_image || {}).url}
				url={location.href}

			/>

			<article className={`${styles.header}`}>

				<header>

					<div className={styles.title}>

						{parseHtml(name)}

					</div>

					{

						description && <RichText className={styles.description} content={description} />

					}

					<div className={styles.links}>

						{
							profile_links.map(({ profile_link, profile_link_title }, index) => 

								<a key={index} href={profile_link.url} target="_blank" rel="noopener noreferrer">

									{(profile_link_title || [{}])[0].text}

								</a>

							)
						}

					</div>

				</header>

				<div className={styles.images}>

					{

						(profile_images || [])

							.map(({ profile_image }) => 

								profile_image ? <ResponsiveImage key={profile_image.url} className={styles.profileImage} src={profile_image.url} /> : null

							)

					}

				</div>

			</article>

			{
				Boolean(posts.length) &&

					<div>

						<div className={styles.feedTitle}>

							Contributions

						</div>

						<PostFeed 

							feed={posts} 
							hasMore={pageInfo.hasNextPage}
							loading={loadingPosts}
							loadMore={() => {

								addPosts({

									query,
									pageInfo,
									prismic,
									feedId: uid

								});

							}}

						/>

					</div>

			}

		</div>

	);

}

ContributorTemplate.fragments = [postHeader];

export default connect(

	(state, { data: { prismic: { contributor, posts: { edges, pageInfo } } } } ) => {

		const feed = state.feed.feeds[contributor._meta.uid] || { items: [], pageInfo };

		return ({

			contributor,

			...feed,

			posts: [

				...edges,

				...feed.items.map(id => state.post.posts[id]).filter(v => v)

			],

			loadingPosts: state.app.loadingPosts

		});

	},

	dispatch => bindActionCreators({ updateApp, addPosts }, dispatch)

)(ContributorTemplate);
